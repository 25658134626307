import React from 'react';
import pic from '../../assets/laptop.svg';

const Intro = () => {
  return (
    <section id='top' className='h-80 flex jcc aic bg-lite'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-7 flex aic'>
            <div className='my-3'>
              <h2 className='title-sub c-blue'>
                Старий ноутбук ветерану - шанс на майбутнє!
              </h2>
              <div className='s-10'></div>
              <h1 className='title-intro-sub c-metal'>
                У вас є старий ноутбук? Подаруйте його ветерану, який прагне
                пройти ІТ-курси та здобути нові навички!
                <br />
                Ми організовуємо волонтерів для передачі ноутбуків тим, хто їх
                потребує, по всій країні.
              </h1>
              <div className='s-30'></div>
              <a className='button blue' href='#form'>
                Подарувати ноутбук
              </a>
              <div className='m-30'></div>
              <a href='#form' className='button outline int'>
                Отримати ноутбук
              </a>
            </div>
          </div>
          <div className='col-lg-5 flex aic my-3'>
            <img src={pic} alt={'veteran'} width={714} height={434} />
          </div>
        </div>
      </div>
    </section>
  );
};

export { Intro };
