import React, { useState } from 'react';
import { MDBAccordion, MDBAccordionItem, MDBContainer } from 'mdb-react-ui-kit';

const FAQ = () => {
  const [donor, setDonor] = useState(true);
  const [veteran, setVeteran] = useState(false);
  const [volonteer, setVolonteer] = useState(false);

  const handleDonor = () => {
    setDonor(true);
    setVeteran(false);
    setVolonteer(false);
  };
  const handleVeteran = () => {
    setDonor(false);
    setVeteran(true);
    setVolonteer(false);
  };
  const handleVolonteer = () => {
    setDonor(false);
    setVeteran(false);
    setVolonteer(true);
  };
  return (
    <section id='faq' className='bg-white'>
      <div className='s-60'></div>
      <div className='content'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <button
                onClick={handleDonor}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  donor && 'active'
                }`}
              >
                <span className='title-icon-2 w-20 flex jcc aic pt-1 h-20'>
                  💻
                </span>
                <span className='c-medium px-3'>Для донорів</span>
              </button>
              <div className='s-20'></div>
              <button
                onClick={handleVeteran}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  veteran && 'active'
                } `}
              >
                <span className='title-icon-1 w-20 flex jcc aic pt-1 h-20'>
                  🎖️
                </span>
                <span className='c-medium px-3'>Для ветеранів</span>
              </button>
              <div className='s-20'></div>
              <button
                onClick={handleVolonteer}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  volonteer && 'active'
                }`}
              >
                <span className='title-icon-3 w-20 flex jcc aic pt-1 h-20'>
                  🤝
                </span>
                <span className='c-medium px-3'>Для волонтерів</span>
              </button>
            </div>
            <div className='col-lg-8 px-5'>
              <div className='card-res how'>
                {donor && (
                  <>
                    <h2 className='title-sub c-medium mb-4'>
                      Часті питання для донорів
                    </h2>
                    <div className='s-10'></div>
                    <div className='line'></div>
                    <MDBContainer>
                      <MDBAccordion>
                        <MDBAccordionItem
                          collapseId={5}
                          headerTitle='Як запакувати ноутбук для відправки?'
                        >
                          Рекомендуємо використовувати міцну упаковку та
                          обгорнути ноутбук захисним матеріалом, щоб уникнути
                          пошкоджень при доставці.
                        </MDBAccordionItem>
                        <MDBAccordionItem
                          collapseId={6}
                          headerTitle='Чи можу я відстежувати посилку?'
                        >
                          Так, після відправки ви отримаєте номер для
                          відстеження, щоб перевірити статус доставки.
                        </MDBAccordionItem>
                        <MDBAccordionItem
                          collapseId={7}
                          headerTitle='Як обрати волонтера для відправки?'
                        >
                          Ви можете обрати волонтера за його місцем розташування
                          або за кількістю ветеранів, які потребують ноутбуків у
                          його регіоні.
                        </MDBAccordionItem>
                      </MDBAccordion>
                    </MDBContainer>
                    <div className='s-10'></div>
                  </>
                )}
                {veteran && (
                  <>
                    <h2 className='title-sub c-medium mb-4'>
                      Часті питання для ветеранів
                    </h2>
                    <div className='s-10'></div>
                    <div className='line'></div>
                    <MDBContainer>
                      <MDBAccordion>
                        <MDBAccordionItem
                          collapseId={8}
                          headerTitle='Як отримати ноутбук?'
                        >
                          Зареєструйтесь на платформі, оберіть пункт прийому та
                          чекайте, поки волонтер зв’яжеться з вами для передачі
                          ноутбука.
                        </MDBAccordionItem>
                        <MDBAccordionItem
                          collapseId={9}
                          headerTitle='Чи є ноутбуки безкоштовними?'
                        >
                          Так, усі ноутбуки надаються безкоштовно для ветеранів,
                          які бажають розвивати свої ІТ-навички.
                        </MDBAccordionItem>
                        <MDBAccordionItem
                          collapseId={10}
                          headerTitle='Що робити, якщо ноутбук має несправності?'
                        >
                          Якщо ноутбук має технічні проблеми, зв’яжіться з
                          волонтером для заміни або ремонту.
                        </MDBAccordionItem>
                      </MDBAccordion>
                    </MDBContainer>
                    <div className='s-10'></div>
                  </>
                )}
                {volonteer && (
                  <>
                    <h2 className='title-sub c-medium mb-4'>
                      Часті питання для волонтерів
                    </h2>
                    <div className='s-10'></div>
                    <div className='line'></div>
                    <MDBContainer>
                      <MDBAccordion>
                        <MDBAccordionItem
                          collapseId={11}
                          headerTitle='Як отримати ноутбук від донора?'
                        >
                          Після реєстрації волонтера, ви отримаєте повідомлення
                          про відправку ноутбука донором. Ви зможете отримати
                          його на пошті.
                        </MDBAccordionItem>
                        <MDBAccordionItem
                          collapseId={12}
                          headerTitle='Чи можу я відправити ноутбук ветерану поштою?'
                        >
                          Так, ви можете домовитися з ветераном про пересилку,
                          якщо особиста передача не є можливою.
                        </MDBAccordionItem>
                        <MDBAccordionItem
                          collapseId={13}
                          headerTitle='Які звіти потрібно надіслати після передачі ноутбука?'
                        >
                          Після передачі ноутбука, зніміть відео підтвердження
                          та надішліть його через CRM для звітування.
                        </MDBAccordionItem>
                      </MDBAccordion>
                    </MDBContainer>
                    <div className='s-10'></div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='s-60'></div>
    </section>
  );
};
export { FAQ };
