import React from 'react';

const Stories = () => {
  return (
    <section id='stories' className='bg-lite'>
      <div className='s-60'></div>
      <div className='content'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 flex aic'>
              <div className='card-res flex jcc aic bg-metal h-55 tc'>
                <div className='px-5'>
                  <h3 className='title c-lite mb-4'>
                    <p className='title-icon'>🎥</p>
                    Ваша історія успіху тут
                  </h3>
                  <p className='c-lite'>
                    Цей блок чекає на ваше відео! Поділіться моментом, коли ваш
                    ноутбук знайшов нового власника і став частиною чийогось
                    нового початку. Ваш досвід може надихнути інших зробити свій
                    внесок!
                  </p>
                </div>
              </div>
              <div className='w-10'></div>
            </div>
            <div className='col-lg-5 flex aic h-55'>
              <div>
                <h2 className='title-intro c-dark'>
                  Поділися своєю історією успіху!
                </h2>
                <div className='s-10'></div>
                <h1 className='c-metal title-intro-sub'>
                  Станьте першим учасником, чия історія надихне інших!
                  Розкажіть, як ваш ноутбук допоміг ветерану зробити крок до
                  нових можливостей.
                </h1>
                <div className='s-30'></div>
                <a className='button blue' href='#form'>
                  Розпочати зараз
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='s-60'></div>
    </section>
  );
};

export { Stories };
