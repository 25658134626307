import React, { useState } from 'react';
import fl1 from '../../assets/region/kiev.png';
import fl2 from '../../assets/region/lviv.png';
import fl3 from '../../assets/region/odesa.png';
import user from '../../assets/user.svg';

const ListVeterans = () => {
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const handleFirst = () => {
    setFirst(true);
    setSecond(false);
    setThird(false);
  };
  const handleSecond = () => {
    setFirst(false);
    setSecond(true);
    setThird(false);
  };
  const handleThird = () => {
    setFirst(false);
    setSecond(false);
    setThird(true);
  };
  return (
    <section id='veterans' className='bg-white'>
      <div className='s-60'></div>
      <div className='content'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <button
                onClick={handleFirst}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  first && 'active'
                }`}
              >
                <img
                  src={fl1}
                  alt='Київська область'
                  width={56}
                  height={56}
                  className='flag'
                />
                <div className='tl'>
                  <span className='c-medium title-mini px-3'>
                    Київська область
                  </span>

                  <span className='c-metal title-mini px-3'>4 волонтера</span>
                </div>
              </button>
              <div className='s-20'></div>
              <button
                onClick={handleSecond}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  second && 'active'
                } `}
              >
                <img
                  src={fl2}
                  alt='Київська область'
                  width={56}
                  height={56}
                  className='flag'
                />
                <div className='tl'>
                  <span className='c-medium title-mini px-3'>
                    Львівська область
                  </span>

                  <span className='c-metal title-mini px-3'>6 волонтерів</span>
                </div>
              </button>
              <div className='s-20'></div>
              <button
                onClick={handleThird}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  third && 'active'
                }`}
              >
                <img
                  src={fl3}
                  alt='Київська область'
                  width={56}
                  height={56}
                  className='flag'
                />
                <div className='tl'>
                  <span className='c-medium title-mini px-3'>
                    Одеська область
                  </span>

                  <span className='c-metal title-mini px-3'>8 волонтерів</span>
                </div>
              </button>
            </div>
            <div className='col-lg-8 pl-5'>
              <div className='card-res how'>
                <h2 className='title-sub c-medium mb-4'>
                  Отримайте доступ до CRM волонтерів
                </h2>

                {first && (
                  <>
                    <p className='title-intro-sub'>
                      <img
                        src={user}
                        alt='Київська область'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        Станьте першим щоб отримати ноутбук
                      </span>
                    </p>
                    <p className='title-intro-sub'>
                      <img
                        src={user}
                        alt='Київська область'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        Станьте першим щоб отримати ноутбук
                      </span>
                    </p>
                    <p className='title-intro-sub'>
                      <img
                        src={user}
                        alt='Київська область'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        Станьте першим щоб отримати ноутбук
                      </span>
                    </p>
                  </>
                )}
                {second && (
                  <>
                    <p className='title-intro-sub'>
                      <img
                        src={user}
                        alt='Київська область'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        Станьте першим щоб отримати ноутбук
                      </span>
                    </p>
                    <p className='title-intro-sub'>
                      <img
                        src={user}
                        alt='Київська область'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        Станьте першим щоб отримати ноутбук
                      </span>
                    </p>
                    <p className='title-intro-sub'>
                      <img
                        src={user}
                        alt='Київська область'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        Станьте першим щоб отримати ноутбук
                      </span>
                    </p>
                  </>
                )}
                {third && (
                  <>
                    <p className='title-intro-sub'>
                      <img
                        src={user}
                        alt='Київська область'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        Станьте першим щоб отримати ноутбук
                      </span>
                    </p>
                    <p className='title-intro-sub'>
                      <img
                        src={user}
                        alt='Київська область'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        Станьте першим щоб отримати ноутбук
                      </span>
                    </p>
                    <p className='title-intro-sub'>
                      <img
                        src={user}
                        alt='Київська область'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        Станьте першим щоб отримати ноутбук
                      </span>
                    </p>
                  </>
                )}
                <div className='s-20'></div>
                <a href='#form' className='button blue'>
                  Зареєструватися
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='s-60'></div>
    </section>
  );
};

export { ListVeterans };
