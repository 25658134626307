import React from 'react';
import logo from '../logo.svg';
import { BsTwitterX, BsFacebook, BsLinkedin } from 'react-icons/bs';

const Footer = () => {
  return (
    <footer id='contact'>
      <div>
        <div className='s-40'></div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 py-3'>
              <div className='flex'>
                <img src={logo} alt='CF IT-SPROUT' width={48} height={48} />
                <span className='px-3'>
                  <a href='tel:+380503240484' className=' c-medium'>
                    +380503240484
                  </a>
                  <br />
                  <a href='mailto:admin@it-sprout.org' className=' c-medium'>
                    admin@it-sprout.org
                  </a>
                </span>
              </div>
            </div>
            <div className='col-lg-6 tr py-3'>
              <div className='s-10'></div>
              <a href='/' className='mx-3 c-medium'>
                <BsLinkedin size={24} />
              </a>
              <a href='/' className='mx-3 c-medium'>
                <BsFacebook size={24} />
              </a>
              <a href='/' className='mx-3 c-medium'>
                <BsTwitterX size={24} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 py-3'>
            <a href='/' className='c-medium title-mini'>
              © 2022-2024 - CF IT-SPROUT. Всi права захищено.
            </a>
          </div>
          <div className='col-lg-6 c-medium title-mini tr py-3'>
            <a href='/cookie' className='mx-3'>
              Полiтика Cookie
            </a>
            <a href='/privacy' className='mx-3'>
              Полiтика Конфiденцiйностi
            </a>
          </div>
        </div>
        <div className='s-20'></div>
      </div>
    </footer>
  );
};

export { Footer };
