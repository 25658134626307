import React, { useState } from 'react';

const HowWork = () => {
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const handleFirst = () => {
    setFirst(true);
    setSecond(false);
    setThird(false);
  };
  const handleSecond = () => {
    setFirst(false);
    setSecond(true);
    setThird(false);
  };
  const handleThird = () => {
    setFirst(false);
    setSecond(false);
    setThird(true);
  };

  return (
    <section id='how' className='bg-white'>
      <div className='s-60'></div>
      <div className='content'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <button
                onClick={handleFirst}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  first && 'active'
                }`}
              >
                <span className='title-icon-2 w-20 flex jcc aic pt-1 h-20'>
                  💻
                </span>
                <span className='c-medium px-3'>Для донорів</span>
              </button>
              <div className='s-20'></div>
              <button
                onClick={handleSecond}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  second && 'active'
                } `}
              >
                <span className='title-icon-1 w-20 flex jcc aic pt-1 h-20'>
                  🎖️
                </span>
                <span className='c-medium px-3'>Для ветеранів</span>
              </button>
              <div className='s-20'></div>
              <button
                onClick={handleThird}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  third && 'active'
                }`}
              >
                <span className='title-icon-3 w-20 flex jcc aic pt-1 h-20'>
                  🤝
                </span>
                <span className='c-medium px-3'>Для волонтерів</span>
              </button>
            </div>
            <div className='col-lg-8 pl-5'>
              <div className='card-res how'>
                {first && (
                  <>
                    <h2 className='title-sub c-medium mb-4'>
                      Як це працює для донорів?
                    </h2>
                    <p className='title-intro-sub'>
                      📜<span className='w-5 d-inline-block'></span>
                      Зареєструйтеся та виберіть волонтера.
                    </p>
                    <p className='title-intro-sub'>
                      📦<span className='w-5 d-inline-block'></span>Відправте
                      ноутбук волонтеру.
                    </p>
                    <p className='title-intro-sub'>
                      🎥<span className='w-5 d-inline-block'></span>Отримайте
                      відеопідтвердження передачі.
                    </p>
                  </>
                )}
                {second && (
                  <>
                    <h2 className='title-sub c-medium mb-4'>
                      Як це працює для ветеранів?
                    </h2>
                    <p className='title-intro-sub'>
                      📝<span className='w-5 d-inline-block'></span>
                      Зареєструйтеся для отримання ноутбука.
                    </p>
                    <p className='title-intro-sub'>
                      🚚<span className='w-5 d-inline-block'></span>Отримайте
                      доставку від волонтера.
                    </p>
                    <p className='title-intro-sub'>
                      🖥️ <span className='w-5 d-inline-block'></span>Почніть
                      навчання на новому ноутбуці.
                    </p>
                  </>
                )}
                {third && (
                  <>
                    <h2 className='title-sub c-medium mb-4'>
                      Як це працює для волонтерів?
                    </h2>
                    <p className='title-intro-sub'>
                      ✍️<span className='w-5 d-inline-block'></span>
                      Реєструйтесь у проєкті.
                    </p>
                    <p className='title-intro-sub'>
                      📨<span className='w-5 d-inline-block'></span>Отримайте
                      ноутбук від донора.
                    </p>
                    <p className='title-intro-sub'>
                      📹 <span className='w-5 d-inline-block'></span>Зніміть
                      передачу та надішліть відеозвіт.
                    </p>
                  </>
                )}
                <div className='s-20'></div>
                <a href='#form' className='button blue'>
                  Зареєструватися
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='s-60'></div>
    </section>
  );
};

export { HowWork };
