import React from 'react';
import { Nav } from '../components/Nav';
import { Intro } from './parts/Intro';
import { HowWork } from './parts/HowWork';
import { Stories } from './parts/Stories';
import { Footer } from '../components/Footer';
import { PopUp } from './parts/PopUp';
import { ListVeterans } from './parts/ListVeterans';
import { ListVolonteers } from './parts/ListVolonteers';
import { Form } from './parts/Form';
import { FAQ } from './parts/FAQ';

const Home = () => {
  return (
    <>
      <PopUp />
      <Nav />
      <Intro />
      <HowWork />
      <Stories />
      <ListVeterans />
      <ListVolonteers />
      <Form />
      <FAQ />
      <Footer />
    </>
  );
};

export { Home };
