import React, { useState } from 'react';
import fl1 from '../../assets/region/kiev.png';
import fl2 from '../../assets/region/lviv.png';
import fl3 from '../../assets/region/odesa.png';
import m1 from '../../assets/laptops/macbook_000001.jpeg';
import m2 from '../../assets/laptops/macbook_000002.jpeg';
import m3 from '../../assets/laptops/macbook_000003.jpeg';

const ListVolonteers = () => {
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const handleFirst = () => {
    setFirst(true);
    setSecond(false);
    setThird(false);
  };
  const handleSecond = () => {
    setFirst(false);
    setSecond(true);
    setThird(false);
  };
  const handleThird = () => {
    setFirst(false);
    setSecond(false);
    setThird(true);
  };
  return (
    <section id='veterans' className='bg-white'>
      <div className='content'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 pr-5'>
              <div className='card-res how'>
                <h2 className='title-sub c-medium mb-4'>
                  Замовити ноутбук і розпочати навчання
                </h2>

                {first && (
                  <>
                    <p className='title-intro-sub'>
                      <img
                        src={m1}
                        alt='MacBook Air M1 512GB'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        <strong>MacBook Air M1 512GB</strong>
                        <br />
                        <br />
                        Потужний ноутбук на базі M1, ідеально підходить для
                        багатозадачності та творчих завдань.
                      </span>
                    </p>
                  </>
                )}
                {second && (
                  <>
                    <p className='title-intro-sub'>
                      <img
                        src={m2}
                        alt='Lenovo V130-14IKB'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        <strong>Lenovo V130-14IKB</strong>
                        <br />
                        <br />
                        Ноутбук для офісних завдань, з Core i5, 8GB RAM і SSD
                        256GB. Підходить для бізнесу та навчання
                      </span>
                    </p>
                  </>
                )}
                {third && (
                  <>
                    <p className='title-intro-sub'>
                      <img
                        src={m3}
                        alt='Asus Windows 11'
                        width={28}
                        height={28}
                      />
                      <span className='px-3'>
                        <strong>Asus Windows 11</strong>
                        <br />
                        <br />
                        Новий ноутбук з Windows 11, підходить для базових
                        завдань і повсякденного використання.
                      </span>
                    </p>
                  </>
                )}
                <div className='s-20'></div>
                <a href='#form' className='button blue'>
                  Зареєструватися
                </a>
              </div>
            </div>
            <div className='col-lg-4'>
              <button
                onClick={handleFirst}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  first && 'active'
                }`}
              >
                <img
                  src={fl1}
                  alt='Київська область'
                  width={56}
                  height={56}
                  className='flag'
                />
                <div className='tl'>
                  <span className='c-medium title-mini px-3'>
                    Київська область
                  </span>

                  <span className='c-metal title-mini px-3'>3 ноутбука</span>
                </div>
              </button>
              <div className='s-20'></div>
              <button
                onClick={handleSecond}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  second && 'active'
                } `}
              >
                <img
                  src={fl2}
                  alt='Київська область'
                  width={56}
                  height={56}
                  className='flag'
                />
                <div className='tl'>
                  <span className='c-medium title-mini px-3'>
                    Львівська область
                  </span>

                  <span className='c-metal title-mini px-3'>5 ноутбуків</span>
                </div>
              </button>
              <div className='s-20'></div>
              <button
                onClick={handleThird}
                className={`title c-metal card-res tab flex aic b-none bg-lite ${
                  third && 'active'
                }`}
              >
                <img
                  src={fl3}
                  alt='Київська область'
                  width={56}
                  height={56}
                  className='flag'
                />
                <div className='tl'>
                  <span className='c-medium title-mini px-3'>
                    Одеська область
                  </span>

                  <span className='c-metal title-mini px-3'>9 ноутбуків</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='s-60'></div>
    </section>
  );
};

export { ListVolonteers };
