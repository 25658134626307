import React, { useEffect } from 'react';

const url =
  'https://tally.so/embed/3qWJR8?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1';

const Form = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://tally.so/widgets/embed.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.Tally) {
        window.Tally.loadEmbeds();
      }
    };

    return () => {
      // Удаляем скрипт при размонтировании компонента
      document.body.removeChild(script);
    };
  }, []);

  return (
    <section id='form'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-6 laptop'></div>
          <div className='col-lg-6 bg-lite px-5'>
            <div className='m-5'>
              <iframe
                data-tally-src={url}
                loading='lazy'
                width='100%'
                height='529'
                frameBorder='0'
                marginHeight='0'
                marginWidth='0'
                title='БФ IT-Паросток'
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Form };
