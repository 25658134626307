import React, { useState, useEffect } from 'react';
import logo from '../assets/logo-full.svg';

const Nav = () => {
  const [opened, setOpened] = useState(true);

  useEffect(() => {
    if (window.innerWidth < 769 && !opened) {
      document.body.classList.add('menu-active');
    } else {
      document.body.classList.remove('menu-active');
    }
  }, [opened]);

  const toggleMenu = () => {
    setOpened(!opened);
  };

  useEffect(() => {
    const handleHashChange = () => {
      if (window.location.hash) {
        window.history.replaceState(null, null, ' ');
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <header>
      <div className='container'>
        <nav>
          <h1 id='logo' className='flex aic'>
            <a href='#top' aria-label='Logotype'>
              <img src={logo} width={200} height={48} alt='CF IT-SPROUT' />
            </a>
          </h1>
          <div
            className={opened ? 'burger' : 'burger open'}
            onClick={toggleMenu}
          >
            <span className='tt'></span>
            <span className='mm'></span>
            <span className='bb'></span>
          </div>
          <div className={opened ? 'menu active' : 'menu'}>
            <a href='#how' className='mx-2 px-1' onClick={toggleMenu}>
              Про нас
            </a>
            <a href='#form' className='mx-2 px-1' onClick={toggleMenu}>
              Отримати ноутбук
            </a>
            <a href='#form' className='mx-2 px-1' onClick={toggleMenu}>
              Подарувати
            </a>
            <a href='#faq' className='mx-2 px-1' onClick={toggleMenu}>
              Часті питання
            </a>
            <a href='#contact' className='mx-2 px-1' onClick={toggleMenu}>
              Контакти
            </a>
            <a href='#form' className='button outline mob-button-show'>
              Стати волонтером
            </a>
          </div>
          <a href='#form' className='button outline mob-button'>
            Для організацій
          </a>
        </nav>
      </div>
    </header>
  );
};

export { Nav };
